import React, { useState } from "react";
import styles from "./Header.module.css";
import Logo from "../../img/Onsite Logo On.png";
import useIsMobile from "../IsMobile";

import { FiMenu } from "react-icons/fi";

function Header() {
  const [menuOpen, setMenuOpen] = useState(false);
  const isMobile = useIsMobile();

  function ScrollTop() {
    window.scrollTo(0, 0);
    setMenuOpen(false);
  }

  function scrollToElement(event, id, offset = 150) {
    event.preventDefault();
    const element = document.getElementById(id);
    if (element) {
      // Calcula a posição do elemento alvo
      const rect = element.getBoundingClientRect();
      const elementTop = rect.top + window.scrollY;

      // Ajusta a rolagem para compensar o offset
      window.scrollTo({
        top: elementTop - offset,
        behavior: "smooth",
      });

      setMenuOpen(false);
    }
  }

  function openInNewTab(link) {
    window.open(link, "_blank");
  }

  return (
    <div id={styles.Header}>
      <div className={styles.content}>
        <img src={Logo} alt="Logo OnSite Tecnologia" />
        <FiMenu
          className={`${isMobile ? styles.mobile : styles.desktop}`}
          size={"2rem"}
          onClick={() => setMenuOpen(!menuOpen)}
        />
        <div
          className={`${styles.menus} ${
            isMobile ? styles.mobile : styles.desktop
          } ${menuOpen ? styles.menuOpen : styles.menuClosed}`}
        >
          <a href="#" onClick={() => ScrollTop()}>
            <div className={styles.menu}>Home</div>
          </a>
          <a href="#Downloads" onClick={(e) => scrollToElement(e, "Downloads")}>
            <div className={styles.menu}>Downloads</div>
          </a>
          <a href="#Contatos" onClick={(e) => scrollToElement(e, "Contatos")}>
            <div className={styles.menu}>Contatos</div>
          </a>
          <a
            onClick={() =>
              openInNewTab(
                "https://autenticador.secullum.com.br/Authorization?response_type=code&client_id=3&redirect_uri=https%3A%2F%2Fpontoweb.secullum.com.br%2FAuth"
              )
            }
          >
            <div className={`${styles.menu} ${styles.ponto}`}>Ponto Web</div>
          </a>
        </div>
      </div>
    </div>
  );
}

export default Header;
